<template>

  <div class="container">
    <div class="page-header">
      <span v-if="query === ''" class="text-truncate">Showing {{items.length}} of {{meta.total}} users</span>
      <span
        v-else
        class="text-truncate"
        data-test="txt-search-result">Found {{meta.total}} users that match '{{query}}'</span>
      <router-link
        v-if="can(uiPermissions.CUSTOMERS_CREATE)"
        to="/users/new"
        data-test="btn-new-user"
        class="btn btn-primary ml-auto">
        New user
      </router-link>
    </div>
    <div class="row">

      <div class="col">
        <div class="card">
          <div class="table-responsive">
            <table data-test="table-users" class="table table-hover table-outline table-vcenter text-nowrap card-table">
              <thead>
                <tr>
                  <th class="w-1"></th>
                  <th>User</th>
                  <th>Phone</th>
                  <th colspan="1">Status</th>
                  <th>Roles</th>
                  <th class="w-1">Last updated</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in items" :key="item.id">
                  <td class="text-center">
                    <span class="avatar" :style="`background-image: url(${identicon(item.user.email)});`"></span>
                  </td>
                  <td>
                    <div>
                      <router-link v-if="can(uiPermissions.CUSTOMERS_VIEW)" :to="`/users/${item.id}`">{{truncate(displayName(item.user))}}</router-link>
                      <span v-else>{{truncate(displayName(item.user))}}</span>
                    </div>
                    <div class="small text-muted">{{truncate(item.user.email)}}</div>
                  </td>
                  <td>
                    <a :href="`tel:${item.user.phone}`">{{item.user.phone}}</a>
                  </td>
                  <td>
                    <div class="small text-muted">Subscriptions</div>
                    <div>
                      <a :href="`/subscriptions?query=${item.user.email}`">{{item.subscriptions.filter(subscription => subscription.status === SubscriptionStatuses.Active).length}}</a> active
                    </div>
                  </td>
                  <td>{{item.user.roles.join(', ')}}</td>
                  <td>
                    {{moment.utc(item.updatedAt).local().fromNow()}}
                  </td>
                  <td class="text-center">
                    <div v-if="can([uiPermissions.CUSTOMERS_VIEW])" class="item-action dropdown">
                      <a
                        tabindex="0"
                        data-toggle="dropdown"
                        class="icon"><i class="fe fe-more-vertical"></i></a>
                      <div class="dropdown-menu dropdown-menu-right">
                        <router-link
                          v-if="can(uiPermissions.CUSTOMERS_VIEW)"
                          :to="`/users/${item.id}`"
                          class="dropdown-item">
                          <i class="dropdown-icon fe fe-edit"></i> Edit
                        </router-link>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr v-if="items.length === 0">
                  <td colspan="9">There are no results matching your criteria.</td>
                </tr>
              </tbody>
            </table>
          </div>
          <pagination
            :currentPage="page"
            :totalPages="meta.totalPages"
            @goToPage="goToPage"></pagination>
        </div>
      </div>

    </div>
  </div>

</template>

<script>

import Pagination from '@/components/Pagination.vue';
import list from '@/mixins/list';
import {customers} from '@/services';
import SubscriptionStatuses from '@hellochef/shared-js/enums/SubscriptionStatuses';

export default {
  components: {
    Pagination,
  },
  mixins: [
    list,
  ],
  data() {
    return {
      SubscriptionStatuses,
    };
  },
  methods: {
    fetchData(page) {
      return customers.getByParameters({
        limit: this.limit,
        page,
        query: this.query,
      });
    },
  },
};

</script>
